<template>
  <HeaderHome />
  <FiltroEvento />
  <Loader :show="loading" />
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Esqueci minha senha</h1>
          <p class="text-justify">
            Preencha o e-mail que você deseja gerar uma nova senha no campo
            abaixo.
          </p>
          <div class="container-fluid form-box2">
            <form ref="resetpassword" @submit.prevent="reset">
              <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>E-mail *</label>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    required="required"
                    placeholder="E-mail"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group text-center">
                  <button @click.prevent="reset" class="btn btn-success">
                    SOLICITAR NOVA SENHA
                  </button>
                </div>
              </div>
            </div>
            </form>
          </div>
          <Atalhos />
        </div>
      </div>
    </div>
  </div>
  <Newsletter />
  <Footer />
  <FooterEnd />
</template>

<script>
import { defineComponent } from "vue";
import HeaderHome from "@/components/HeaderHome.vue";
import FiltroEvento from "@/components/FiltroEvento.vue";
import Footer from "@/components/Footer.vue";
import FooterEnd from "@/components/FooterEnd.vue";
import Newsletter from "@/components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import User from '@/api/User';

import Swal from "sweetalert2";
import Loader from "@/components/Loader.vue";

export default defineComponent({
  name: "RecoveryPage",
  components: {
    Loader,
    HeaderHome,
    FiltroEvento,
    Footer,
    FooterEnd,
    Newsletter,
    Atalhos,
  },
  data() {
    return {
      email: "",
      loading:false,
    };
  },
  methods: {
    reset() {
      if(!this.$refs.resetpassword.checkValidity()){
        this.$refs.resetpassword.reportValidity();
        return;
      }
      this.loading = true;
      User.reset_password(this.email).then((request) => {
        if(request.status === 200){
          Swal.fire({
            icon: 'success',
            title: 'Um E-mail com uma nova senha foi enviado.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar:true,
          });
        }else if(request.status === 404){
          Swal.fire({
            icon: 'error',
            title: 'Email não foi localizado em nossa base.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar:true,
          });
        }

        this.loading = false;
      });
    },
  },
});
</script>

<style>
</style>
